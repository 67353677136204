import { requests } from "@/utils/requests";

export const getContract = async (contract_uuid) => {
  let query = "";
  if (contract_uuid != null) query = `contract_uuid=${contract_uuid}`;
  const data = await requests({
    url: `/api/admin/contract?${query}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const getAllocUser = async (contract_uuid) => {
  const data = await requests({
    url: `/api/admin/contract/${contract_uuid}/users`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const delAllocUser = async (contract_uuid, user_uuid) => {
  const data = await requests({
    url: `/api/admin/contract/${contract_uuid}/users/${user_uuid}`,
    method: "DELETE",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const newAllocUser = async (contract_uuid, user_uuid) => {
  const data = await requests({
    url: `/api/admin/contract/${contract_uuid}/users`,
    data: { user_uuid },
    method: "POST",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const getAllocProd = async (product_uuid) => {
  const data = await requests({
    url: `/api/admin/contract/${product_uuid}/prod`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const delAllocProd = async (contract_uuid, product_uuid) => {
  const data = await requests({
    url: `/api/admin/contract/${contract_uuid}/prod/${product_uuid}`,
    method: "DELETE",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const newAllocProd = async (contract_uuid, product_uuid) => {
  const data = await requests({
    url: `/api/admin/contract/${contract_uuid}/prod`,
    data: { product_uuid },
    method: "POST",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const putAllocProd = async ({
  contract_uuid,
  product_uuid,
  contr_prod_start_date,
  contr_prod_end_date,
  contr_prod_is_active,
  contr_prod_is_in_grace_period,
}) => {
  const data = await requests({
    url: `/api/admin/contract/${contract_uuid}/prod`,
    data: {
      product_uuid,
      contr_prod_start_date,
      contr_prod_end_date,
      contr_prod_is_active,
      contr_prod_is_in_grace_period,
    },
    method: "PUT",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const newContract = async ({
  contract_name,
  master_company,
  customer_company,
  contract_success_offerings,
  contract_start_date,
  contract_end_date,
  contract_is_active,
  contract_is_in_grace_period,
  contract_extends,
}) => {
  const data = await requests({
    url: "/api/admin/contract",
    method: "POST",
    data: {
      contract_name,
      master_company,
      customer_company,
      contract_success_offerings,
      contract_start_date,
      contract_end_date,
      contract_is_active,
      contract_is_in_grace_period,
      contract_extends,
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const putContract = async (
  contract_uuid,
  {
    contract_name,
    master_company,
    customer_company,
    contract_success_offerings,
    contract_start_date,
    contract_end_date,
    contract_is_active,
    contract_is_in_grace_period,
    contract_extends,
  }
) => {
  let query = "";
  if (contract_uuid != null) query = `contract_uuid=${contract_uuid}`;
  const data = await requests({
    url: `/api/admin/contract?${query}`,
    method: "PUT",
    data: {
      contract_name,
      master_company,
      customer_company,
      contract_success_offerings,
      contract_start_date,
      contract_end_date,
      contract_is_active,
      contract_is_in_grace_period,
      contract_extends,
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const delContract = async (contract_uuid) => {
  let query = "";
  if (contract_uuid != null) query = `contract_uuid=${contract_uuid}`;
  const data = await requests({
    url: `/api/admin/contract?${query}`,
    method: "DELETE",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const getContractByCustomer = async (customer_company) => {
  const data = await requests({
    url: `/api/admin/contract/company/${customer_company}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export default {
  getContract,
  newContract,
  putContract,
  delContract,
  getContractByCustomer,
};
