<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>관리자 - 프로젝트(계약) 관리</h1>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-btn color="#FF4D00" dark block @click="$router.push('/admin')">
          BACK
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">Contract List</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" light @click="newContractBtn">
              <v-icon>mdi-plus</v-icon>
              New Contract
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="contractSearch"
                    append-icon="mdi-magnify"
                    label="계약 검색"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col> </v-col>
              </v-row>
            </v-container>
            <v-data-table
              :headers="contractHeader"
              :items="contractList"
              class="elevation-0"
              :items-per-page="15"
              height="500px"
              :search="contractSearch"
              dense
            >
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click="allocUsers(item)">
                  mdi-account
                </v-icon>
                <v-icon small class="mr-2" @click="allocProd(item)">
                  mdi-archive-cog
                </v-icon>
                <v-icon small class="mr-2" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:item.contract_start_date="{ item }">
                {{ item.contract_start_date.split("T")[0] }}
              </template>
              <template v-slot:item.contract_end_date="{ item }">
                {{ item.contract_end_date.split("T")[0] }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>
          <h5>삭제시 복구가 불가능 합니다.<br />정말 삭제 하시겠습니까?</h5>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteItemConfirm">
            삭제
          </v-btn>
          <v-btn color="blue darken-1" text @click="closeDelete">취소</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewContract" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">New Contract</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row class="pb-2">
              <v-col cols="12"><h2>Contract Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="계약명"
                  class="px-1 pt-0"
                  v-model="contract_frm.contract_name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="contract_frm.master_company"
                  outlined
                  light
                  dense
                  hide-details
                  label="파트너사 UUID"
                  :items="companyList"
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="contract_frm.customer_company"
                  outlined
                  light
                  dense
                  hide-details
                  label="고객사 UUID"
                  :items="companyList"
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="contract_frm.contract_success_offerings"
                  outlined
                  light
                  dense
                  hide-details
                  label="Success offerings"
                  :items="successOfferings"
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  type="date"
                  label="시작일"
                  class="px-1 pt-0"
                  v-model="contract_frm.contract_start_date"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  type="date"
                  label="만료일"
                  class="px-1 pt-0"
                  v-model="contract_frm.contract_end_date"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="text-center">
                <v-switch
                  v-model="contract_frm.contract_is_active"
                  :label="`is active: ${
                    contract_frm.contract_is_active ? 'Y' : 'N'
                  }`"
                  color="indigo"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="auto" class="text-center">
                <v-switch
                  v-model="contract_frm.contract_is_in_grace_period"
                  :label="`in grace period: ${
                    contract_frm.contract_is_in_grace_period ? 'Y' : 'N'
                  }`"
                  color="indigo"
                  hide-details
                ></v-switch>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="#FF4D00" dark block @click="newContractSaveBtn">
                  SAVE
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditContract" max-width="800px">
      <v-card v-if="dialogEditContract">
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">Edit Contract</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row class="pb-2">
              <v-col cols="12"><h2>Contract Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="계약명"
                  class="px-1 pt-0"
                  v-model="edit_contract_frm.contract_name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="edit_contract_frm.master_company"
                  outlined
                  light
                  dense
                  hide-details
                  label="파트너사 UUID"
                  :items="companyList"
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="edit_contract_frm.customer_company"
                  outlined
                  light
                  dense
                  hide-details
                  label="고객사 UUID"
                  :items="companyList"
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="edit_contract_frm.contract_success_offerings"
                  outlined
                  light
                  dense
                  hide-details
                  label="Success offerings"
                  :items="successOfferings"
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  type="date"
                  label="시작일"
                  class="px-1 pt-0"
                  v-model="edit_contract_frm.contract_start_date"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  type="date"
                  label="만료일"
                  class="px-1 pt-0"
                  v-model="edit_contract_frm.contract_end_date"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto" class="text-center">
                <v-switch
                  v-model="edit_contract_frm.contract_is_active"
                  :label="`is active: ${
                    edit_contract_frm.contract_is_active ? 'Y' : 'N'
                  }`"
                  color="indigo"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="auto" class="text-center">
                <v-switch
                  v-model="edit_contract_frm.contract_is_in_grace_period"
                  :label="`in grace period: ${
                    edit_contract_frm.contract_is_in_grace_period ? 'Y' : 'N'
                  }`"
                  color="indigo"
                  hide-details
                ></v-switch>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="#FF4D00" dark block @click="editContractSaveBtn">
                  SAVE
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAllocUsers" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">사용자 할당 목록</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" light @click="newAllocUserDialog">
              <v-icon>mdi-plus</v-icon>
              New User
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row class="pb-2">
              <v-col cols="12"><h2>Contract Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="계약 ID"
                  class="px-1 pt-0"
                  readonly
                  v-model="contract_account_alloc_item.contract_uuid"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="계약 명"
                  class="px-1 pt-0"
                  readonly
                  v-model="contract_account_alloc_item.contract_name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="contract_account_alloc_item.master_company"
                  outlined
                  light
                  dense
                  hide-details
                  label="파트너사"
                  :items="companyList"
                  readonly
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="contract_account_alloc_item.customer_company"
                  outlined
                  light
                  dense
                  hide-details
                  label="고객사"
                  :items="companyList"
                  readonly
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"><h2>할당된 사용자 목록</h2></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="allocatedHeader"
                  :items="allocatedUsers"
                  class="elevation-0"
                  dense
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="deleteAllocUser(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn
                  color="#FF4D00"
                  dark
                  block
                  @click="dialogAllocUsers = false"
                >
                  CLOSE <v-icon small class="ml-2"> mdi-close </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAllocUsers_unalloc" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">사용자 할당하기</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row>
              <v-col cols="12"><h2>사용자 목록</h2></v-col>
            </v-row>
            <v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="accountSearch"
                    append-icon="mdi-magnify"
                    label="사용자 검색"
                    single-line
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="accountHeader"
                  :items="accountList"
                  :search="accountSearch"
                  class="elevation-0"
                  dense
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="newAllocUser(item)">
                      mdi-plus
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn
                  color="#FF4D00"
                  dark
                  block
                  @click="dialogAllocUsers_unalloc = false"
                >
                  CLOSE <v-icon small class="ml-2"> mdi-close </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAllocProd" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">제품 할당 목록</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" light @click="newAllocProdDialog">
              <v-icon>mdi-plus</v-icon>
              New Product
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row class="pb-2">
              <v-col cols="12"><h2>Contract Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="계약 ID"
                  class="px-1 pt-0"
                  readonly
                  v-model="contract_product_alloc_item.contract_uuid"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="계약 명"
                  class="px-1 pt-0"
                  readonly
                  v-model="contract_product_alloc_item.contract_name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="contract_product_alloc_item.master_company"
                  outlined
                  light
                  dense
                  hide-details
                  label="파트너사"
                  :items="companyList"
                  readonly
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="contract_product_alloc_item.customer_company"
                  outlined
                  light
                  dense
                  hide-details
                  label="고객사"
                  :items="companyList"
                  readonly
                  class="px-1 pt-0"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"><h2>할당된 제품 목록</h2></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="allocatedProductHeader"
                  :items="allocatedProds"
                  class="elevation-0"
                  dense
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="showExpiredProdMdl(item)"
                    >
                      mdi-cog
                    </v-icon>
                    <v-icon small class="mr-2" @click="deleteAllocProd(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <template v-slot:item.contr_prod_end_date="{ item }">
                    {{ item.contr_prod_end_date.split("T")[0] }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn
                  color="#FF4D00"
                  dark
                  block
                  @click="dialogAllocProd = false"
                >
                  CLOSE <v-icon small class="ml-2"> mdi-close </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAllocProd_unalloc" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">제품 할당하기</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row>
              <v-col cols="12"><h2>제품 목록</h2></v-col>
            </v-row>
            <v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="productSearch"
                    append-icon="mdi-magnify"
                    label="제품 검색"
                    single-line
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="productHeader"
                  :items="productList"
                  :search="productSearch"
                  class="elevation-0"
                  dense
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="newAllocProd(item)">
                      mdi-plus
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn
                  color="#FF4D00"
                  dark
                  block
                  @click="dialogAllocProd_unalloc = false"
                >
                  CLOSE <v-icon small class="ml-2"> mdi-close </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSetProdExpired" max-width="400px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">제품별 만료일 설정</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="my-3">
            <v-row class="my-0">
              <v-col cols="12" class="py-0">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  type="date"
                  label="만료일"
                  class="px-1 pt-0"
                  v-model="selected_contract_product_info.contr_prod_end_date"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-spacer></v-spacer>
              <v-col cols="auto" class="text-center py-0">
                <v-switch
                  v-model="selected_contract_product_info.contr_prod_is_active"
                  :label="`is active: ${
                    selected_contract_product_info.contr_prod_is_active
                      ? 'Y'
                      : 'N'
                  }`"
                  color="indigo"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="auto" class="text-center py-0">
                <v-switch
                  v-model="
                    selected_contract_product_info.contr_prod_is_in_grace_period
                  "
                  :label="`in grace period: ${
                    selected_contract_product_info.contr_prod_is_in_grace_period
                      ? 'Y'
                      : 'N'
                  }`"
                  color="indigo"
                  hide-details
                ></v-switch>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row>
              <v-col>
                <v-btn small block class="success" @click="saveExpiredProd">
                  <v-icon class="mr-2" size="10pt"> mdi-check </v-icon>
                  SAVE
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getContract,
  delContract,
  newContract,
  putContract,
  getAllocUser,
  delAllocUser,
  newAllocUser,
  getAllocProd,
  delAllocProd,
  newAllocProd,
  putAllocProd,
} from "@/api/Admin/Contract";
import { getCompany } from "@/api/Admin/Company";
import { getAccounts } from "@/api/Admin/Accounts";
import { getProduct } from "@/api/Admin/Product";
import { getSuccessOfferings } from "@/api/Presets";
import randomstring from "randomstring";
export default {
  mounted() {
    this.getContract();
    this.getCompany();
    this.getSuccessOfferings();
  },
  methods: {
    async getContract() {
      const data = await getContract();
      this.contractList = [];
      if (data.success) {
        this.contractList = data.data;
      }
    },
    async getAccounts() {
      const data = await getAccounts();
      this.accountList = [];
      const allocated = this.allocatedUsers.map((e) => e.user_uuid);
      if (data.success) {
        this.accountList = data.data.filter(
          (e) => !allocated.includes(e.user_uuid)
        );
      }
    },
    async getCompany() {
      const data = await getCompany();
      this.companyList = [];
      if (data.success) {
        this.companyList = data.data;
        for (const company of this.companyList) {
          company.text = company.company_name;
          company.value = company.company_uuid;
        }
      }
    },
    async getProduct() {
      const data = await getProduct();
      this.productList = [];
      const allocated = this.allocatedProds.map((e) => e.product_uuid);
      if (data.success) {
        this.productList = data.data.filter(
          (e) => !allocated.includes(e.product_uuid)
        );
      }
    },
    async getSuccessOfferings() {
      const data = await getSuccessOfferings();
      this.successOfferings = [];
      if (data.success) {
        this.successOfferings = data.data;
        for (const offering of this.successOfferings) {
          offering.text = offering.offering_name;
          offering.value = offering.contract_success_offerings;
        }
      }
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedItem = null;
    },
    async deleteItemConfirm() {
      await delContract(this.selectedItem.contract_uuid);
      this.dialogDelete = false;
      this.selectedItem = null;
      this.getContract();
    },
    newContractBtn() {
      this.contract_frm = {
        ontract_name: null,
        master_company: null,
        customer_company: null,
        contract_success_offerings: null,
        contract_start_date: null,
        contract_end_date: null,
        contract_is_active: true,
        contract_is_in_grace_period: false,
      };
      this.dialogNewContract = true;
    },
    async newContractSaveBtn() {
      await newContract(this.contract_frm);
      this.dialogNewContract = false;
      await this.getContract();
    },
    passAutoFill() {
      const str = randomstring.generate(7);
      this.contract_frm.contract_pw = str;
    },
    editItem(item) {
      this.edit_contract_frm = item;
      this.edit_contract_frm.contract_start_date =
        item.contract_start_date.split("T")[0];
      this.edit_contract_frm.contract_end_date =
        item.contract_end_date.split("T")[0];
      this.dialogEditContract = true;
    },
    async editContractSaveBtn() {
      await putContract(
        this.edit_contract_frm.contract_uuid,
        this.edit_contract_frm
      );
      this.dialogEditContract = false;
      await this.getContract();
    },
    async allocUsers(item) {
      this.contract_account_alloc_item = item;
      this.contract_account_alloc_frm.contract_uuid = item.contract_uuid;

      this.allocatedUsers = [];
      const data = await getAllocUser(
        this.contract_account_alloc_item.contract_uuid
      );
      if (data.success) {
        this.allocatedUsers = data.data;
      }
      this.dialogAllocUsers = true;
    },
    async newAllocUserDialog() {
      this.dialogAllocUsers_unalloc = true;
      await this.getAccounts();
    },
    async deleteAllocUser(item) {
      await delAllocUser(item.contract_uuid, item.user_uuid);
      await this.allocUsers(this.contract_account_alloc_item);
    },
    async newAllocUser(item) {
      await newAllocUser(
        this.contract_account_alloc_item.contract_uuid,
        item.user_uuid
      );
      await this.allocUsers(this.contract_account_alloc_item);
      await this.getAccounts();
    },
    async allocProd(item) {
      this.contract_product_alloc_item = item;
      this.contract_product_alloc_frm.contract_uuid = item.contract_uuid;

      this.allocatedProds = [];
      const data = await getAllocProd(
        this.contract_product_alloc_item.contract_uuid
      );
      if (data.success) {
        this.allocatedProds = data.data;
      }
      this.dialogAllocProd = true;
    },
    async newAllocProdDialog() {
      this.dialogAllocProd_unalloc = true;
      await this.getProduct();
    },
    async deleteAllocProd(item) {
      await delAllocProd(item.contract_uuid, item.product_uuid);
      await this.allocProd(this.contract_product_alloc_item);
    },
    async newAllocProd(item) {
      await newAllocProd(
        this.contract_product_alloc_item.contract_uuid,
        item.product_uuid
      );
      await this.allocProd(this.contract_product_alloc_item);
      await this.getProduct();
    },
    async showExpiredProdMdl(item) {
      console.log(item);
      this.selected_contract_product_info = item;
      this.selected_contract_product_info.contr_prod_start_date =
        item.contr_prod_start_date.split("T")[0];
      this.selected_contract_product_info.contr_prod_end_date =
        item.contr_prod_end_date.split("T")[0];
      this.dialogSetProdExpired = true;
    },
    async saveExpiredProd() {
      const data = await putAllocProd(this.selected_contract_product_info);
      if (data.success) {
        this.dialogSetProdExpired = false;
      }
    },
  },
  data() {
    return {
      contractSearch: null,
      contractList: [],
      companyList: [],
      successOfferings: [],
      contractHeader: [
        {
          text: "계약 명",
          align: "start",
          sortable: true,
          value: "contract_name",
        },
        {
          text: "파트너사",
          align: "start",
          value: "a_master_company.company_name",
        },
        {
          text: "고객사",
          align: "start",
          sortable: false,
          value: "a_customer_company.company_name",
        },

        {
          text: "시작일",
          align: "start",
          sortable: false,
          value: "contract_start_date",
        },
        {
          text: "만료일",
          align: "start",
          sortable: false,
          value: "contract_end_date",
        },
        {
          text: "Success Offerings",
          align: "start",
          sortable: false,
          value: "TB_PRST_SUCCESS_OFFERING.offering_name",
        },
        {
          text: "Activate",
          align: "start",
          sortable: false,
          value: "contract_is_active",
        },

        {
          text: "In Grace Period",
          align: "start",
          sortable: false,
          value: "contract_is_in_grace_period",
        },

        { text: "Actions", value: "actions", sortable: false },
      ],
      selectedItem: null,
      dialogDelete: false,
      dialogNewContract: false,
      dialogEditContract: false,
      dialogAllocUsers: false,
      dialogAllocUsers_unalloc: false,
      dialogAllocProd: false,
      dialogAllocProd_unalloc: false,
      dialogSetProdExpired: false,
      contract_frm: {
        contract_name: null,
        master_company: null,
        customer_company: null,
        contract_success_offerings: null,
        contract_start_date: null,
        contract_end_date: null,
        contract_is_active: true,
        contract_is_in_grace_period: false,
      },
      edit_contract_frm: {
        ontract_name: null,
        master_company: null,
        customer_company: null,
        contract_success_offerings: null,
        contract_start_date: null,
        contract_end_date: null,
        contract_is_active: true,
        contract_is_in_grace_period: false,
      },

      allocatedUsers: [],
      accountSearch: "",
      accountList: [],
      allocatedHeader: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "TB_USER_ACCOUNT_LIST.user_id",
        },
        {
          text: "이름",
          align: "start",
          sortable: false,
          value: "TB_USER_ACCOUNT_LIST.user_name_first_kor",
        },
        {
          text: "성",
          align: "start",
          sortable: false,
          value: "TB_USER_ACCOUNT_LIST.user_name_last_kor",
        },
        {
          text: "연락처",
          align: "start",
          sortable: false,
          value: "TB_USER_ACCOUNT_LIST.user_phone_number",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      accountHeader: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "user_id",
        },
        {
          text: "이름",
          align: "start",
          sortable: false,
          value: "user_name_first_kor",
        },
        {
          text: "성",
          align: "start",
          sortable: false,
          value: "user_name_last_kor",
        },
        {
          text: "연락처",
          align: "start",
          sortable: false,
          value: "user_phone_number",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      contract_account_alloc_item: {},
      contract_account_alloc_frm: {
        contract_uuid: null,
        user_uuid: null,
      },

      allocatedProds: [],
      productSearch: "",
      productList: [],
      allocatedProductHeader: [
        {
          text: "제품명",
          align: "start",
          sortable: true,
          value: "TB_INFO_PRODUCT_LIST.product_name",
        },
        {
          text: "만료일",
          align: "start",
          sortable: false,
          value: "contr_prod_end_date",
        },
        {
          text: "Activate",
          align: "start",
          sortable: false,
          value: "contr_prod_is_active",
        },
        {
          text: "In Grace Period",
          align: "start",
          sortable: false,
          value: "contr_prod_is_in_grace_period",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      productHeader: [
        {
          text: "제품명",
          align: "start",
          sortable: true,
          value: "product_name",
        },
        {
          text: "고유코드",
          align: "start",
          value: "product_uuid",
        },
        {
          text: "설명",
          align: "start",
          sortable: false,
          value: "product_description",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      contract_product_alloc_item: {},
      contract_product_alloc_frm: {
        contract_uuid: null,
        product_uuid: null,
      },

      selected_contract_product_info: {
        product_uuid: null,
        contract_uuid: null,
        contr_prod_start_date: null,
        contr_prod_end_date: null,
        contr_prod_is_active: null,
        contr_prod_is_in_grace_period: null,
      },
    };
  },
};
</script>
