import { requests } from "@/utils/requests";

export const getProduct = async (product_uuid) => {
  let query = "";
  if (product_uuid != null) query = `product_uuid=${product_uuid}`;
  const data = await requests({
    url: `/api/admin/product?${query}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const newProduct = async ({
  product_name,
  product_description,
  product_type_cloud,
  product_type_etc,
}) => {
  const data = await requests({
    url: "/api/admin/product",
    method: "POST",
    data: {
      product_name,
      product_description,
      product_type_cloud,
      product_type_etc,
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const putProduct = async (
  product_uuid,
  { product_name, product_description, product_type_cloud, product_type_etc }
) => {
  let query = "";
  if (product_uuid != null) query = `product_uuid=${product_uuid}`;
  const data = await requests({
    url: `/api/admin/product?${query}`,
    method: "PUT",
    data: {
      product_name,
      product_description,
      product_type_cloud,
      product_type_etc,
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const delProduct = async (product_uuid) => {
  let query = "";
  if (product_uuid != null) query = `product_uuid=${product_uuid}`;
  const data = await requests({
    url: `/api/admin/product?${query}`,
    method: "DELETE",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const getProductByContract = async (contract_uuid) => {
  const data = await requests({
    url: `/api/admin/product/contract/${contract_uuid}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const getProductVersion = async (product_uuid) => {
  const data = await requests({
    url: `/api/admin/product/version/${product_uuid}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const newProductVersion = async ({ product_uuid, product_version }) => {
  const data = await requests({
    url: "/api/admin/product/version",
    method: "POST",
    data: {
      product_uuid,
      product_version,
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const delProductVersion = async ({ product_uuid, product_version }) => {
  const data = await requests({
    url: `/api/admin/product/version/${product_uuid}/${product_version}`,
    method: "DELETE",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export default {
  getProduct,
  newProduct,
  putProduct,
  delProduct,
  getProductByContract,
  getProductVersion,
  newProductVersion,
  delProductVersion,
};
